export const TokenList = [
  {
    id: 1,
    name: 'Aave TokenList',
    token: 81,
    fetchURI: 'https://www.gemini.com/uniswap/manifest.json',
    isEnabled: false,
  },
]

export const mumbaiTokenList = [
  {
    address: '0x5093af5dF5EAfd96B518a11cfb32c37DA2f8f0C3',
    balance: '',
    chainId: 80001,
    decimals: 18,
    logoURI: 'https://i.imgur.com/1gvlSeH.png',
    name: 'Unilend Finance Token',
    symbol: 'UFT',
    underlyingBalance: '',
  },
  {
    address: '0xbcC80cCbDe188d34D35018602dC3f56766bA377D',
    balance: '',
    chainId: 80001,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700',
    name: 'Link',
    symbol: 'LINK',
    underlyingBalance: '',
  },
  {
    address: '0x3167C03E094a951Da7AE6abFACf83705a3096b9f',
    balance: '',
    chainId: 80001,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14243/small/aUSDT.78f5faae.png?1615528400',
    name: 'USDT',
    symbol: 'USDT',
    underlyingBalance: '',
  },
]
